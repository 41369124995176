.content {
  width: 100%;
  padding: 40px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #1a1a1a;
  min-height: 100vh;
}

.particles-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.content-container {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 1920px;
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 0 20px;
}

.content-left {
  flex: 1;
  padding-right: 20px;
}

.content-left h2 {
  font-size: 2.8rem;
  margin-bottom: 1.5rem;
  color: #ffffff;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
}

.content-left p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #e0e0e0;
  margin-bottom: 2rem;
  transform: translateX(-50px);
  opacity: 0;
  animation: slideAndGlow 1s ease forwards;
  animation-delay: 1.2s;
}

.animated-title {
  display: inline-block;
  font-size: 2.8em;
  color: #ffffff;
  margin-bottom: 20px;
  position: relative;
  animation: glitchText 5s infinite;
}

.animated-title::before,
.animated-title::after {
  content: 'Welcome to ICG GAMING';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.animated-title::before {
  color: #ff0000;
  animation: glitchText 2s infinite;
  clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
  transform: translate(-2px);
}

.animated-title::after {
  color: #0000ff;
  animation: glitchText 2.5s infinite;
  clip-path: polygon(0 80%, 100% 20%, 100% 100%, 0 100%);
  transform: translate(2px);
}

@keyframes glitchText {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-2px, 2px);
  }
  40% {
    transform: translate(-2px, -2px);
  }
  60% {
    transform: translate(2px, 2px);
  }
  80% {
    transform: translate(2px, -2px);
  }
  100% {
    transform: translate(0);
  }
}

@keyframes slideAndGlow {
  0% {
    transform: translateX(-50px);
    opacity: 0;
    text-shadow: none;
  }
  50% {
    opacity: 0.5;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
  }
}

.cta-button {
  padding: 15px 30px;
  font-size: 1.2rem;
  background: transparent;
  color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 0;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 2px;
  animation: buttonPulse 2s infinite;
}

.cta-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

.cta-button:hover::before {
  left: 100%;
}

.cta-button:hover {
  background: #ffffff;
  color: #000000;
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
}

@keyframes buttonPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.content-right {
  flex: 1;
  max-width: 960px;
  opacity: 0;
  animation: fadeScale 1s ease forwards;
  animation-delay: 0.5s;
}

@keyframes fadeScale {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.image-container {
  width: 100%;
  line-height: 0;
  max-width: 960px;
  margin: 0 auto;
}

.image-container img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  filter: brightness(1.1) contrast(1.1);
}

.image-container:hover img {
  transform: scale(1.02);
}

@media screen and (max-width: 968px) {
  .content-container {
    flex-direction: column;
    text-align: center;
  }

  .content-left {
    padding-right: 0;
    margin-bottom: 40px;
  }

  .content-left h2 {
    font-size: 2rem;
  }

  .content-left p {
    font-size: 1rem;
  }

  .animated-title {
    font-size: 2.2em;
  }
}
